<template>
    <div class="cx-header">
        <div class="cx-header__content">
            <v-toolbar color="#e41319"  dark dense fixed>
                <v-btn @click="clickLeft" small text icon>
                    <v-icon>{{iconLeft}}</v-icon>
                </v-btn>
            <v-toolbar-title>{{title}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <div v-if="iconRight !== null ">
                <v-btn @click="clickRight" small text icon>
                    <v-icon>{{iconRight}}</v-icon>
                </v-btn>
            </div>
                <slot>

                </slot>

            </v-toolbar>
        </div>
    </div>
</template>

<script>
    export default {
        name: "cx-header",
        props:{
            iconLeft:{
                type: String,
                default: null
            },
            iconRight:{
                type: String,
                default: null
            },
            title:{
                type: String,
                default: null
            }
        },
        methods: {
            clickLeft() {
                this.$emit("clickLeft")
            },
            clickRight() {
                this.$emit("clickRight")
            }
        }
    }
</script>
<style lang="scss">
    .cx-header {
        height: 48px;
    }
    .cx-header__content {
        position: fixed;
        z-index: 9999;
        width: 100%;
    }
</style>
