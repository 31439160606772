<template>
    <div class="text-center" style="display:flex;justify-content:center;align-items:center;">
        <div>
            <v-progress-circular indeterminate color="orange" ></v-progress-circular>
            <div class='mt-6'>{{texte}}</div>
        </div>
    </div >
</template>
<script>
    export default {
        name: "cx-waiting",
        props: {
            texte: {
                type: String,
                default: 'Chargement en cours'
            },
        },
    }
</script>
