<template>
    <v-navigation-drawer v-model="drawer" style=" position: fixed;" :absolute='true' :temporary='true'
                         class="cx-menu-pwa">
        <v-list-item>
            <v-icon color="grey darken-1" @click='() => (this.$emit("closeMenu"))'>close</v-icon>
            <span color="grey darken-1" class='mx-auto'>{{ dataUser.name }}</span>
        </v-list-item>
        <v-divider></v-divider>

        <cx-menu-list :items="dataMenu"></cx-menu-list>

        <div style="position: fixed;bottom: 0;" class='text-center mb-2'>
            <v-btn @click="logoutPost" icon class='mb-2'>
                <v-icon color="#fd7e14" size='36'>power_settings_new</v-icon>
            </v-btn>
            <v-btn depressed @click="updatePwd">Modifier mot de passe</v-btn>
            <div>Version : {{ require('../../../package.json').version }}</div>
        </div>
    </v-navigation-drawer>
</template>
<script>
import CxMenuList from "./CxMenuList";

export default {
    name: 'cx-menu-pwa',
    components: {
        CxMenuList
    },
    data() {
        return {
            dialogPassword: false,
            dataMenu: [
                {
                    id: 117,
                    icon: 'home',
                    title: 'Accueil',
                    component: 'vx-accueil',
                    categorie: 'primary',
                    badge: 0,
                    ordre: 1
                },
                {
                    id: 118,
                    icon: 'calendar_today',
                    title: 'Mon Activité',
                    component: 'vx-interventions',
                    categorie: 'primary',
                    badge: 0,
                    ordre: 2
                }
                ,
                {
                    id: 119,
                    icon: 'folder',
                    title: 'Mes Documents',
                    component: 'vx-documents',
                    categorie: 'primary',
                    badge: 0,
                    ordre: 3
                },
                {
                    id: 206,
                    icon: 'fa-building',
                    title: 'Mes Bâtiments',
                    component: 'vx-mes-batiments',
                    categorie: 'primary',
                    badge: 0,
                    ordre: 4
                }
            ],
            dataUser: {
                name: null
            }
        }
    },
    mounted() {
        this.dataUser.name = this.$store.getters.getUserInfos().name
    },
    updated() {
        if (!this.dataUser.name) this.dataUser.name = this.$store.getters.getUserInfos().name;
    },
    computed: {
        drawer: {
            get() {
                return this.$store.getters.getSidebarState()
            },
            set(val) {
                this.$store.commit('SIDEBAR', val)
            }
        }
    },
    methods: {
        logoutPost() {
            this.dataMenu = null
            this.$auth.logout()
        },
        updatePwd() {
            this.$store.commit('TAB_COMPONENT', {
                component: 'vx-update-password',
                title: 'Modifier Mot de Passe'
            });
        }
    }
}
</script>
<style lang="scss">
    .cx-menu-pwa {
        z-index: 10000;
    }
</style>
