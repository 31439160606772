<template>
    <div>
        <cx-header icon-left="menu" :title="currentTitle" @clickLeft="openMenu"></cx-header>
        <cx-waiting v-if="loading" style='position:fixed;height:100vh;width:100vw;z-index:99999;background-color:white;top:0px'></cx-waiting>
        <cx-menu-pwa v-if="!loading" style="background: #f0f5fb" @closeMenu="closeMenu"/>
        
         <slot>
            <v-container class="pa-0">
                <component v-bind:is="currentTabComponent" :dataProvider="currentData"/>
            </v-container>
        </slot> 

        <!-- REFRESH DE MISE A JOUR -->
        <div class="text-center" >
        <v-dialog
            v-model="dialog"
            width="500"
        >
          <v-card>
            <v-card-title class="headline grey lighten-2">
              Information
            </v-card-title>

            <v-card-text>
              La version de votre application n'est pas à jour. Veuillez recharger la page afin d'obtenir la nouvelle version
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
               <v-btn
                  color="primary"
                  text
                  @click="close"
              >
                Annuler
              </v-btn>
              <v-btn
                  color="primary"
                  text
                  @click="handleClick"
              >
                Ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        </div>
        
    </div>
</template>
<script>
import CxMenuPwa from '../components/menu/CxMenuPwa'
import CxHeader from '../components/menu/CxHeader'
import CxWaiting from '../components/message/CxWaiting'


export default {
    name: "vx-main",
    components: {
        CxHeader,
        CxMenuPwa,
        CxWaiting
    },
    data() {
        return {
            active: false,
            loading: false,
            dialog: false
        }
    },
    mounted() {

        this.$auth.getUserTechnicienInformation().catch((error)=>{
            this.$services.powerLib.notification.showSnackbar({
                message : 'Une erreur de configuration est présente sur votre profil, veuilez contacter le support: ' + error,
                type : 'error',
            })
        })

        this.$root.$on('start-loading', () => {
            this.setLoading(true)
        }),
         this.$root.$on('stop-loading', () => {
            this.setLoading(false)
        })

        this.getVersion()
        
    },
    updated() {
        this.getVersion();
    },
    computed: {
        currentTabComponent: function () {
            return this.$store.getters.currentTabComponent()
        },
        currentTitle: function () {
            return this.$store.getters.currentTitle()
        },
        currentData: function () {
            return this.$store.getters.currentData()
        }
    },
    methods: {
        openMenu() {
            this.$store.commit('SIDEBAR', true)
        },
        closeMenu() {
            this.$store.commit('SIDEBAR', false);
        },
        setLoading(val){
            this.loading = val
        },
        close(){
        this.dialog = false;
        },
        // DOC : https://developer.mozilla.org/fr/docs/Web/API/Storage/clear
        handleClick() {
            this.dialog = false;
            localStorage.clear();
            window.location.reload();
        },
        async getVersion(){
            let lastVersion = await this.$services.request.getJson('api/pwa/general/configuration');
            let currentVersion = require('../../package.json').version;
            
            (lastVersion !== currentVersion) ? this.dialog = true : ''

        }
    }
}
</script>

<style lang="scss">
    .global-main-title {
        background-color:#25207a;
        text-align:center;
        padding:5px;
        color:white;
        font-weight:bold
    }
    .global-main-card {
        padding:20px;
        background-color:#eff4fa !important;
    }

</style>
