<template>
    <v-list class="pt-2" dark :dense="false" :expand="false" :light="false" :subheader="false" :three-line="false" :two-line="false">
        <v-list-item class="grey--text" v-for="item in items" v-bind:key="item.icon" @click="actionForItem( item )">
            <span style="margin-right: 20px;">
                <v-icon color="grey darken-1">{{ item.icon }}</v-icon> 
            </span> 
            {{ item.title }}
        </v-list-item>
    </v-list>
</template>
<script>
    export default {
        name: 'px-menu-user',
        props: {
            items: {},
        },
        computed: {
            filteredList() {
                return this.items.filter(item => {
                    return item.title.toLowerCase().includes(this.search.toLowerCase())
                })
            }
        },
        methods: {
            actionForItem(item) {
                        this.$store.commit('TAB_COMPONENT', item);
            },
        }
    }
</script>
